.el-date-editor.el-input[data-v-084de95d], .el-date-editor.el-input__inner[data-v-084de95d] {
  width: 180px !important;
}
.right_box[data-v-084de95d] {
  width: 25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}
.save_box[data-v-084de95d] {
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center[data-v-084de95d] {
  text-align: center;
  color: #333 !important;
}
.weight[data-v-084de95d] {
  font-size: 13px !important;
}
.left[data-v-084de95d] {
  text-align: left;
}
.right[data-v-084de95d] {
  text-align: right;
}
.left_k[data-v-084de95d] {
  text-indent: 41px;
}
.content[data-v-084de95d] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content span[data-v-084de95d] {
  line-height: 26px;
  font-size: 13px;
}
.content tr[data-v-084de95d] {
  padding: 0;
  margin: 0;
}
.content td[data-v-084de95d] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 26px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.content input[data-v-084de95d] {
  width: 100%;
  line-height: 26px;
  font-size: 13px;
}
.big_box4[data-v-084de95d] {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}